<template>
  <section>
    <v-data-table
      :headers="headers"
      :items="loads"
      hide-actions
      class="elevation-1"
    >
      <template slot="items" slot-scope="props">
        <td><router-link :to="`/analytics/models/${props.item.id}`">{{ props.item.name }}</router-link></td>
        <td class="text-xs-left">{{ props.item.currentPower }} kW</td>
        <td class="text-xs-left">{{ props.item.peak }} kW</td>
        <td class="text-xs-left">{{ props.item.type }}</td>
        <td class="text-xs-left">{{ props.item.parent }}</td>
        <td class="text-xs-left">{{ props.item.location }}</td>
        <td class="text-xs-left">{{ props.item.powerMonitor }}</td>
        <td class="text-xs-left">
          <v-tooltip bottom>
            <span slot="activator">about {{ props.item.lastUpdated | fromNow }}</span>
            <span>{{ props.item.lastUpdated | toLocaleString }}</span>
          </v-tooltip>
        </td>
        <td class="text-xs-left"></td>
      </template>
    </v-data-table>
  </section>
</template>

<script>
import moment from "moment";

export default {
  name: "LoadsModule",

  filters: {
    fromNow(date) {
      return moment(date).fromNow();
    },

    toLocaleString(date) {
      return date.toLocaleString();
    }
  },

  created() {
    this.loads = [
      { id: 1, name: "Transformer T1", currentPower: 23.4, peak: 25.1, type: "Plug Load", parent: "Mains-30", location: "First Floor", powerMonitor: "eGauge39155", lastUpdated: new Date("2018-10-25") },
      { id: 2, name: "Transformer T2", currentPower: 12.5, peak: 20.5, type: "Plug Load", parent: "Mains-30", location: "Second Floor", powerMonitor: "eGauge39155", lastUpdated: new Date("2018-10-24") },
      { id: 3, name: "AC1", currentPower: 17.3, peak: 29.0, type: "HVAC", parent: "Mains-30", location: "First Floor", powerMonitor: "eGauge39155", lastUpdated: new Date("2018-10-24") },
      { id: 4, name: "AC2", currentPower: 19.1, peak: 33.1, type: "HVAC", parent: "Mains-30", location: "Second Floor", powerMonitor: "eGauge39155", lastUpdated: new Date("2018-10-24") },
      { id: 5, name: "Mains", currentPower: 12.5, peak: 20.5, type: "Meter", parent: null, location: null, powerMonitor: "eGauge39155", lastUpdated: new Date("2018-10-24") },
    ];
  },

  mounted() {
  },

  data() {
    return {
      headers: [
        { text: 'Load Name', value: 'name' },
        { text: 'Current Power', value: 'currentPower' },
        { text: 'Peak', value: 'peak' },
        { text: 'Type', value: 'type' },
        { text: 'Parent', value: 'parent' },
        { text: 'Location', value: 'location' },
        { text: 'Power Monitor', value: 'powerMonitor' },
        { text: 'Last Updated', value: 'lastUpdated' }
      ],

      loads: []
    };
  }
}
</script>
